export enum ApiResourceModule {
	ADVERTISEMENTS = 'res_banners',
	COMPANIES = 'res_companies',
	GROUPS = 'res_groups',
	MODULES = 'res_modules',
	PERMISSIONS = 'res_permissions',
	PROFILE = 'res_profile',
	TENANTS = 'res_tenants',
	THEMES = 'res_themes',
	USER_SETTINGS = 'res_user_settings',
	USERS = 'res_users',
}
